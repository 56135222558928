/*----------------------------------------------------------------------------*
	$C-BLOCK
*----------------------------------------------------------------------------*/


*:not(br):not(tr):not(html):not(head):not(meta):not(title):not(style)
	font-family: $base-font-family
	font-size: 16px
	font-weight: 400
	color: $base-text-color
	suavizadoTexto()


/* ^.c-block-container
-----------------------------------------------------------------------------*/

.c-block-container-full
	background: #ebeff2


.c-block-container
	width: 90%
	max-width: 700px
	margin: 0 auto


.c-block-container-2
	width: 100%
	padding: 0 25px;

/* ^Fin - .c-block-container
-----------------------------------------------------------------------------*/





/* ^.c-block-white
-----------------------------------------------------------------------------*/

.c-block-white
	border-radius: 5px
	background: #fff

/* ^Fin - .c-block-white
-----------------------------------------------------------------------------*/





/* ^.c-block-info
-----------------------------------------------------------------------------*/

.c-block-info
	padding: 20px

	p
		font-size: 16px
		line-height: 1.5


.c-block-info--center
	@extends .c-block-info
	text-align: center


.c-block-info__col
	width: 50%

	&:first-child
		padding-right: 10px

	&:last-child
		padding-left: 10px


.c-block-info__col-der
	min-width: 130px
	padding-left: 20px


.c-block-info__img
	margin-bottom: 20px

	img
		display: block
		width: 100%


.c-block-info__separador
	width: 100%
	height: 5px
	margin-bottom: 20px
	border-bottom: 1px solid $base-color


.c-block-info__separador-2
	width: 100%
	height: 30px


.c-block-info__btns
	> *
		margin-right: 12px
		margin-bottom: 10px

		&:last-child
			margin-right: 0


.c-block-info__center
	text-align: center

/* ^Fin - .c-block-info
-----------------------------------------------------------------------------*/





/* ^.c-block-1
-----------------------------------------------------------------------------*/

.c-block-1
	width: 100%


.c-block-1__header
	padding-top: 123px
	border-radius: 5px 5px 0 0
	background: #1d234f url($img-header) left top no-repeat
	height: 219px


.c-block-1__heading
	max-width: 520px
	margin: 0 20px
	padding: 15px
	border-radius: 15px
	border: 4px solid #fff


.c-block-1__h1
	font-family: $base2-font-family
	font-size: 70px
	color: #fff


.c-block-1__text
	font-family: $base2-font-family
	font-size: 22px
	color: #fff

/* ^Fin - .c-block-1
-----------------------------------------------------------------------------*/





/* ^.c-block-2
-----------------------------------------------------------------------------*/

.c-block-2__box-1
	width: 50%
	padding: 20px 0
	border-right: 1px solid #8b7c57

	&:first-child
		padding-right: 25px

	&:last-child
		padding-left: 25px
		border-right: none


.c-block-2__box-2
	padding-top: 20px
	border-top: 1px solid #8b7c57


.c-block-2__logo
	margin-bottom: 22px


.c-block-2__heading
	display: block
	margin-bottom: 10px
	font-weight: 700
	font-size: 15px
	color: #2f3948


.c-block-2__text-1
	margin-bottom: 7px
	font-size: 14px
	color: #2f3948

	&:last-child
		margin-bottom: 0


.c-block-2__text-2
	@extends .c-block-2__text-1
	margin-bottom: 20px

	&:last-child
		margin-bottom: 0

	strong
		color: inherit
		font-weight: 700
		font-size: 13px


.c-block-2__social
	margin: 0 5px


/* ^Fin - .c-block-2
-----------------------------------------------------------------------------*/





/* ^.c-block-3
-----------------------------------------------------------------------------*/

.c-block-3
	border-radius: 0 0 5px 5px
	background: $base3-color

	p
		color: #fff
		font-size: 16px


.c-block-3__box-1
	width: 100%
	max-width: 230px


.c-block-3__box-2
	width: 100%
	max-width: 550px
	padding-top: 30px
	border-top: 1px solid #fff

.c-block-3__text-1
	font-family: $base2-font-family
	font-size: 22px
	color: $base-color


.c-block-3__text-2
	font-family: $base2-font-family
	font-size: 50px
	color: $base-color


.c-block-3__row
	padding: 30px 20px 0 20px


.c-block-3__row-2
	padding: 30px 20px


.c-block-3__comilla
	display: inline-block
	margin-bottom: 6px

/* ^Fin - .c-block-3
-----------------------------------------------------------------------------*/
