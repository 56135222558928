/*----------------------------------------------------------------------------*
	$O-HEADING
*----------------------------------------------------------------------------*/


/* ^.o-heading-1
-----------------------------------------------------------------------------*/

.o-heading-1
	padding: 15px 20px
	background: $base2-color
	font-family: $base2-font-family
	font-size: 22px
	text-align: center
	color: #fff

/* ^Fin - .o-heading-1
-----------------------------------------------------------------------------*/





/* ^.o-heading-2
-----------------------------------------------------------------------------*/

.o-heading-2
	margin-bottom: 20px
	font-family: $base2-font-family
	font-size: 22px
	color: $base-color


.o-heading-2--v2
	@extends .o-heading-2
	margin-bottom: 5px

/* ^Fin - .o-heading-2
-----------------------------------------------------------------------------*/





/* ^.o-heading-3
-----------------------------------------------------------------------------*/

.o-heading-3
	margin-bottom: 20px
	padding: 10px 15px
	border-radius: 8px
	background: $base-color
	font-family: $base2-font-family
	font-size: 19px
	text-align: right
	color: #fff

/* ^Fin - .o-heading-3
-----------------------------------------------------------------------------*/





/* ^.o-heading-4
-----------------------------------------------------------------------------*/

.o-heading-4
	max-width: 250px
	margin: 0 auto 20px auto
	padding: 10px 15px
	border-radius: 8px
	background: $base2-color
	font-family: $base2-font-family
	font-size: 19px
	text-align: center
	color: #fff

/* ^Fin - .o-heading-4
-----------------------------------------------------------------------------*/
