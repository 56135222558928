/*-----------------------------------------------------------*\
	$BUTTONS
\*-----------------------------------------------------------*/

/*	^btn 'clasicos'
-------------------------------------------------------------*/

// Boton por defecto
.btn
	btn()
	display: inline-flex
	align-items: center
	height: 40px
	padding: 0 25px
	border-radius: (@height / 2)
	background: $colorBase
	color: #fff
	transition: all .3s ease
	
	&:hover
		background: $colorSecundario
		color: #fff

/*	^Fin - btn 'clasicos'
-------------------------------------------------------------*/