/*----------------------------------------------------------------------------*
	$-O-TEXT
*----------------------------------------------------------------------------*/



/* ^.o-text-1
-----------------------------------------------------------------------------*/

.o-text-1
	color: $base-color
	font-weight: $bold

/* ^Fin - .o-text-1
-----------------------------------------------------------------------------*/





/* ^.o-text-2
-----------------------------------------------------------------------------*/

.o-text-2
	margin-bottom: 15px
	font-size: 15px

	&:last-child
		margin-bottom: 0

	strong
		font-weight: $bold

/* ^Fin - .o-text-2
-----------------------------------------------------------------------------*/
