/*----------------------------------------------------------------------------*
	$C-LIST
*----------------------------------------------------------------------------*/


/* ^.c-list-event
-----------------------------------------------------------------------------*/

.c-list-event
	display: inline-table
	margin: 10px 20px


.c-list-event__header
	height: 118px
	background: $base-color url($img-event-header) no-repeat


.c-list-event__footer
	padding: 5px 10px
	background: #fff url($img-event-footer) no-repeat
	font-size: 12px
	line-height: 1.2
	color: $base-color


.c-list-event__text-1,
.c-list-event__text-2,
.c-list-event__text-3
	font-family: $base2-font-family
	color: #fff
	line-height: 1


.c-list-event__text-1
	font-size: 50px


.c-list-event__text-2
	font-size: 17px


.c-list-event__text-3
	font-size: 25px


/* ^Fin - .c-list-event
-----------------------------------------------------------------------------*/
