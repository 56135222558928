/*-----------------------------------------------------------*\
	$RESET
\*-----------------------------------------------------------*/


/**
 *
 * Reiniciar las propiedades de algunas etiquetas que
 * por defecto varian en cada navegador.
 *
 */
body
	margin: 0
	padding: 0



/**
 *
 * Hacer que todas las imágenes sean fluidas y eliminar
 * el borde que se presenta en algunos navegadores al estar
 * envuelto por un ancla.
 *
 */
img
	max-width: 100%
	height: auto
	border: none
