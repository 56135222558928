/*----------------------------------------------------------------------------*
	$O-BTN
*----------------------------------------------------------------------------*/


/* ^.o-btn
-----------------------------------------------------------------------------*/

.o-btn
	btn()
	display: inline-block
	vertical-align: middle
	align-items: center
	padding: 10px 22px
	border-radius: 6px
	background: $base-color
	font-family: $base2-font-family
	text-decoration: none
	font-size: 13px
	color: #fff

/* ^Fin - .o-btn
-----------------------------------------------------------------------------*/




/* ^.o-btn-1
-----------------------------------------------------------------------------*/

.o-btn-1
	@extends .o-btn

/* ^Fin - .o-btn-1
-----------------------------------------------------------------------------*/





/* ^.o-btn-2
-----------------------------------------------------------------------------*/

.o-btn-2
	@extends .o-btn
	padding-top: 8px
	padding-bottom: 8px
	border: 2px solid $base-color
	background: #fff
	color: $base-color

/* ^Fin - .o-btn-2
-----------------------------------------------------------------------------*/
