/*-----------------------------------------------------------*\
	$MIXINS
\*-----------------------------------------------------------*/


/*	^Grilla
-------------------------------------------------------------*/


/**
 *
 * == centrar() ==
 * Usado para crear un contenedor centrado con la opción
 * de que sea flexible y agregar padding left y right.
 *
 * Importante: Se requiere como mínimo el primer parametro
 * ya que es el que define el ancho de la caja.
 *
 * Ejemplo 1:
 *
		.contenedor
			centrar(1170px, 15px)

 * Ejemplo 2:
 *
		.contenedor
			centrar(1170px, pad:15px)
 *
 */
 
centrar($ancho = null, $pad = null)
	
	display: flex
	flex-wrap wrap

	width: 100%
	if $ancho != null
		max-width: $ancho
	
	margin-left: auto
	margin-right: auto
	
	if $pad != null
		padding-left: $pad
		padding-right: $pad
	
			


/**
 *
 * == rem-calc() ==
 * Calcular un valor en unidad de medida `rem`
 *
 */

rem-calc(value)
	u = unit(value);
	if (u is 'rem') {
		return unit(value, u);
	} else {
		return unit(value / $baseFont-size, 'rem')
	}



/**
 *
 * == grid() ==
 * Contenedor de las columnas
 *
 */

grid(direction = 'row', col-align = 'top', justify = '')
	display flex
	flex-wrap wrap
	if col-align is 'top'
		align-items flex-start
	if col-align is 'bottom'
		align-items flex-end
	if col-align is 'center'
		align-items center
	if col-align is 'stretch'
		align-items stretch
	if direction is 'row'
		flex-direction row
	if direction is 'row-reverse'
		flex-direction row-reverse
	if direction is 'column'
		flex-direction column
	if direction is 'column-reverse'
		flex-direction column-reverse
	if justify is 'center'
		justify-content center
	if justify is 'start'
		justify-content flex-start
	if justify is 'end'
		justify-content flex-end



/**
 *
 * == col() ==
 * Calcula ancho para columnas con posibilidad de
 * agregar medianil.
 *
 */
 
col(i = 1, cols = $columns, align = '', g = $gutter)
	flex-basis: s('calc(100% * %s - %s)', i / cols, rem-calc(g))
	min-width 0 // ajuste para las imágenes responsive
	if g is not 0
		margin-right: rem-calc(g / 2)
		margin-left: rem-calc(g / 2)
	if align is 'top'
		align-self flex-start
	if align is 'bottom'
		align-self flex-end
	if align is 'center'
		align-self center
		
/*col(i = 1, cols = $columns, align = '', g = $gutter)
	flex-basis: s('calc(100% * %s - %s)', i / cols, rem-calc((g * (cols - 1) ) / cols ))
	min-width 0 // ajuste para las imágenes responsive
	if g is not 0
		margin-right: rem-calc(g)
	if align is 'top'
		align-self flex-start
	if align is 'bottom'
		align-self flex-end
	if align is 'center'
		align-self center
		

	&:nth-child({i}n)
		margin-right: 0*/
		


		
		


/**
 *
 * == col-offset() ==
 * Margen izquierdo para separar columnas
 *
 */

col-offset(i = 1, cols = $columns, g = $gutter)
	margin-left: s('calc(100% * %s + (%s / 2))', i / cols, rem-calc(g)) !important



/*	^Fin - Grilla
-------------------------------------------------------------*/


	



/**
 *
 * == suavizadoTexto() ==
 * Agregar suavisado al texto
 *
 */

suavizadoTexto()
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale



	



/**
 *
 * == btn() ==
 * Formatear estilos para botones
 *
 */

btn()
	appearance: none
	border: none
	outline: 0
	





/**
 *
 * == blur() ==
 * Agregar desenfoque a un elemento
 *
 */

blur($valor)
	-webkit-filter: unquote("blur("+$valor+")")
	-moz-filter: unquote("blur("+$valor+")")
	-o-filter: unquote("blur("+$valor+")")
	-ms-filter: unquote("blur("+$valor+")")
	filter: unquote("blur("+$valor+")")

